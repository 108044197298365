import React from 'react'
import Footer from './Footer';
import ButtonWpp from './ButtonWpp';

function catalogo() {
  return (
    <div>

      <ButtonWpp />

        <div id="untree_co--overlayer"></div>
    <div className="loader">
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
    
    <nav className="untree_co--site-mobile-menu">
      <div className="close-wrap d-flex">
        <a href="#" className="d-flex ml-auto js-menu-toggle">
          <span className="close-label">Close</span>
          <div className="close-times">
            <span className="bar1"></span>
            <span className="bar2"></span>
          </div>
        </a>
      </div>
      <div className="site-mobile-inner"></div>
    </nav>


    <div className="untree_co--site-wrap">

    <nav className="untree_co--site-nav js-sticky-nav">
        <div className="container d-flex align-items-center">
          <div className="logo-wrap">
            <a href="/"><img className="logo-fitss" src="images/Logo_Principal-removebg-preview.png" alt="Lilica"/></a>
          </div>
          <div className="site-nav-ul-wrap text-center d-none d-lg-block">
            <ul className="site-nav-ul js-clone-nav">
              <li><a href='/'>Home</a></li>
              <li className="active">
              <a href='/catalogo'>Catálogo de produtos</a>
              </li>
              <li><a href="/galeria">Galeria</a></li>
              <li><a href="/lilica">Sobre nós</a></li>
              <li><a href="/contato">Contato</a></li>
            </ul>
          </div>
          <div className="icons-wrap text-md-right">

            <ul className="icons-top d-none d-lg-block">
              
              {/* <li>
                <a href="#"><span className="icon-facebook"></span></a>
              </li> */}
              <li>
              <a href="https://www.instagram.com/lilica.patisseriee" target='_blank'><span className="icon-instagram"></span></a>
              </li>
            </ul>

            {/* Mobile Toggle */}
            <a href="#" className="d-block d-lg-none burger js-menu-toggle" data-toggle="collapse" data-target="#main-navbar">
              <span></span>
            </a>
          </div>
        </div>
      </nav>

      <main className="untree_co--site-main">
        
        <div className="untree_co--site-hero inner-page kfhjc">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-7 text-center">
                <div className="site-hero-contents" data-aos="fade-up">
                  <h1 className="hero-heading text-white">Catálogo de produtos</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="untree_co--site-section pb-0">
          <div className="container-fluid px-md-0">

            <div className="row justify-content-center text-center site-section pt-0">
              <div className="col-md-6">
                <h2 className="display-4 cor" data-aos="fade-up">Nossas Delícias</h2>
              {/* <p data-aos="fade-up" data-aos-delay="100">A hotel is an establishment that provides paid lodging on a short-term basis. Facilities provided may range from a modest-quality mattress </p> */}
              </div>
            </div>
            <div className="row no-gutters align-items-stretch room-animate site-section">
              <div className="col-md-7 img-wrap" data-jarallax-element="-100">
                
                <div className="bg-image h-100 kkkd">
                
                </div>
              </div>
              <div id='bemcasados' className="col-md-5" >
                <div className="row justify-content-center">
                  <div className="col-md-8 py-5">
                    <h3 className="display-4 heading cor">Bem Casados</h3>
                    <div className="room-exerpt">
                      <div className="room-price mb-4 cor"><span className="per">A partir de:<br /></span>R$3,97<span className="per">/unidade</span></div>
                      <p>Perfeito para qualquer ocasião: Casamentos, aniversários, batizados, nascimentos!</p>
                      <p>Oferecemos um produto único, saboroso e lindo!</p>

                      <div className="row mt-5">
                        <div className="col-12">
                          <h3 className="mb-4 cor">Garantimos</h3>
                          <ul className="list-unstyled ul-check">
                            <li className='cor'>Bem casado sem cheiro de ovo</li>
                            <li className='cor'>Fofinho e molhadinho</li>
                            <li className='cor'>Sabor excepcional
                            </li>
                            <li className='cor'>Embalagem e acabamentos perfeitos!</li>
                            <li className='cor'>Vontade de não para de comer!</li>
                          </ul>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>


          <div className="container-fluid px-md-0">
            <div className="row no-gutters align-items-stretch room-animate site-section">
              <div className="col-md-7 order-md-2 img-wrap" data-jarallax-element="-100">
                
                <div className="bg-image h-100 dddaaaw"></div>
                
              </div>
              <div id='doces' className="col-md-5">
                <div className="row justify-content-center">
                  <div className="col-md-8 py-5">
                    <h3 className="display-4 heading cor">Doces</h3>
                    <div className="room-exerpt">
                      <p>Encontre aqui os doces perfeitos para seu evento!</p>
                      <p>Nossos produtos são feitos com os melhores ingredientes, pensando no melhor para o melhor momento!</p>

                      <div className="row mt-5">
                        <div className="col-12">
                          <ul className="ddssaw">
                            <li className='w20kx'>Brigadeiros Tradicional.... <strong>cento R$197</strong>  (pedido mínimo 50 unidades) <br />Sabores: brigadeiro | beijinho | moranguinho | paçoca | coco queimado | ovomaltine.</li>
                            <li className='w20kx'>Brigadeiros Gourmet.... <strong>cento R$297</strong>  (pedido mínimo 50 unidades) <br />Sabores: ninho c/ nutella | ferrero rocher | café | churros | romeu e julieta | choco branco | maracujá | brûlée | pudim.</li>
                            <li className='w20kx'>Brigadeiros Finos.... <strong>cento R$397</strong>  (pedido mínimo 50 unidades) <br />Sabores: red velvet | pistache | banoffe | limão | açaí | caramelo salgado.</li>
                            <li className='w20kx'>Brownies.... <strong>a partir de R$5,00</strong>  (pedido mínimo 30 unidades) <br />Cobertura: tradicional | ganaches | nutella.</li>
                          </ul>
                          <a className="ctas fds dda" href="cardapio.pdf" target="_blank" rel="noopener noreferrer">Confira nosso Cardápio!</a>
                        </div>
                      </div>

                      <div className="row mt-5">
                        <div className="col-12">
                          <h3 className="mb-4 cor">Garantimos</h3>
                          <ul className="list-unstyled ul-check">
                            <li className="mb-4 cor">Sabores tradicionais e exóticos</li>
                            <li className="mb-4 cor">Doces Gourmet e Finos</li>
                            <li className="mb-4 cor">Ingredientes de qualidade</li>
                            <li className="mb-4 cor">Lindos e bem acabados</li>
                            <li className="mb-4 cor">Personalizamos do seu jeito!</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>

    
        <div className="untree_co--site-section py-5 bg-body-darker cta">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h3 className="p-0 cl">Gostaria de experimentar nossos bem casados? Garanta já sua amostra gratuita! <a>Às sextas e sábados!</a></h3>
                <a className="ctas dda" href="https://api.whatsapp.com/send/?phone=5511961206560&text=Ol%C3%A1+vim+pelo+Google%21&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer">Reserve sua amostra!</a>
              </div>
            </div>
          </div>
        </div>


      </main>
      
      <Footer />

    </div>
    {/* Search */}
    <div className="search-wrap">
      <a href="#" className="close-search js-search-toggle">
        <span>Close</span>
      </a>
      <div className="container">
        <div className="row justify-content-center align-items-center text-center">
          <div className="col-md-12">
            <form action="#">
              <input type="search" name="s" id="s" placeholder="Type a keyword and hit enter..."  autoComplete="false" />
            </form>    
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default catalogo