import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import App from './App.jsx'
import Catalogo from './Components/Catalogo.jsx';
import Galeria from './Components/Galeria.jsx';
import Lilica from './Components/Lilica.jsx';
import Contato from './Components/Contato.jsx';
import LP1 from './Lading Pages/lp1.jsx';
import PaginaObrigado from './Lading Pages/PaginaObrigado.jsx';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "catalogo",
    element: <Catalogo />,
  },
  {
    path: "galeria",
    element: <Galeria />,
  },
  {
    path: "lilica",
    element: <Lilica />,
  },
  {
    path: "contato",
    element: <Contato />,
  },
  {
    path: "bemcasados",
    element: <LP1 />,
  },
  {
    path: "obrigado",
    element: <PaginaObrigado />,
  },
]);


createRoot(document.getElementById('root')).render(
  <StrictMode>
     <RouterProvider router={router} />
  </StrictMode>,
)
