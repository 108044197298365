import React, { useState } from 'react';

const Dobra7 = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleFAQ = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const faqs = [
        {
            question: "Qual é a validade dos Bem Casados?",
            answer: "Os bem casados tem uma validade de 5 dias fora da geladeira!"
        },
        {
            question: "Posso fazer uma degustação antes de fechar o pedido?",
            answer: "Com certeza! Entre em contato para agendar uma degustação e experimentar nossos produtos."
        },
        {
            question: "Vocês são de onde?",
            answer: "Somos da Zona Oeste de São Paulo, próximo ao shopping Tiete Plaza."
        },
        {
            question: "Com quanto tempo de antecedência devo fazer minha encomenda?",
            answer: "O ideal é com 30 dias de antecedência, porém dependendo, podemos encaixar!"
        },
        {
            question: "Vocês fazem embalagens personalizadas?",
            answer: "Sim! Oferecemos várias opções de embalagens e cores para tornar o seu pedido ainda mais especial e alinhado ao tema da sua celebração."
        },
        {
            question: "Qual é a quantidade mínima de pedido?",
            answer: "Para garantir a produção artesanal, trabalhamos com um pedido mínimo de 30 unidades."
        },
        {
            question: "Vocês entregam?",
            answer: "Trabalhamos com frete, podemos calcular um para você!"
        }  
    ];

    return (
        <section className="faq-section" id='pergunta'>
            <h2>Perguntas Frequentes</h2>
            <div className="faq-container">
                {faqs.map((faq, index) => (
                    <div 
                        key={index} 
                        className={`faq-item ${activeIndex === index ? 'active' : ''}`} 
                        onClick={() => toggleFAQ(index)}
                    >
                        <div className="faq-question">
                            <h3>{faq.question}</h3>
                        </div>
                        {activeIndex === index && <div className="faq-answer"><p>{faq.answer}</p></div>}
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Dobra7;
