import React from 'react'
import Dobra1 from './Dobras/Dobra1'
import Dobra2 from './Dobras/Dobra2'
import Carousel from './Dobras/Dobra3'
import Dobra4 from './Dobras/Dobra4'
import Dobra6 from './Dobras/Dobra6'
import Dobra7 from './Dobras/Dobra7'

function lp1() {
  return (
    <div>
       <a href="https://api.whatsapp.com/send/?phone=5511961206560&text=Ol%C3%A1+vim+pelo+Google%21&type=phone_number&app_absent=0" className="whatsapp-button">
            <img className='imswe' src="images/whatsapp.png" alt="whatsapp" />
</a>
     <Dobra1 />
     <Dobra2 />
     <Carousel />
     <Dobra4 />
     <Dobra7 />
     <Dobra6 />
    </div>
  )
}

export default lp1