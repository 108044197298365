import Footer from './Components/Footer';
import ButtonWpp from './Components/ButtonWpp';

function App() {
  return (
    <>
    
    <ButtonWpp />

      <div id="untree_co--overlayer"></div>
    <div className="loader">
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>

    
    <nav className="untree_co--site-mobile-menu">
      <div className="close-wrap d-flex">
        <a href="#" className="d-flex ml-auto js-menu-toggle">
          <span className="close-label">Close</span>
          <div className="close-times">
            <span className="bar1"></span>
            <span className="bar2"></span>
          </div>
        </a>
      </div>
      <div className="site-mobile-inner"></div>
    </nav>
    <div className="untree_co--site-wrap">
        <nav className="untree_co--site-nav js-sticky-nav">
        <div className="container d-flex align-items-center">
          <div className="logo-wrap">
            <a href="/"><img className="logo-fitss" src="images/Logo_Principal-removebg-preview.png" alt="Lilica"/></a>
          </div>
          <div className="site-nav-ul-wrap text-center d-none d-lg-block">
            <ul className="site-nav-ul js-clone-nav">
              <li className="active"><a href='/'>Home</a></li>
              <li>
              <a href='/catalogo'>Catálogo de produtos</a>
              </li>
              <li><a href="/galeria">Galeria</a></li>
              <li><a href="/lilica">Sobre nós</a></li>
              <li><a href="/contato">Contato</a></li>
            </ul>
          </div>
          <div className="icons-wrap text-md-right">

            <ul className="icons-top d-none d-lg-block">
              
              {/* <li>
                <a href="#"><span className="icon-facebook"></span></a>
              </li> */}
              <li>
                <a href="https://www.instagram.com/lilica.patisseriee" target='_blank'><span className="icon-instagram"></span></a>
              </li>
            </ul>

            {/* Mobile Toggle */}
            <a href="#" className="d-block d-lg-none burger js-menu-toggle lif" data-toggle="collapse" data-target="#main-navbar">
              <span></span>
            </a>
          </div>
        </div>
      </nav>

      <div className="untree_co--site-main">
        

        <div className="owl-carousel owl-hero">

          <div className="untree_co--site-hero overlay ases">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="col-md-8">
                  <div className="site-hero-contents text-center" data-aos="fade-up">
                    <h1 className="hero-heading">Lembrancinhas Artesanais</h1>
                    <div className="sub-text cl">
                      <p>Cada sabor preparado com muito carinho, pensando e produzindo os melhores produtos para o seu evento.</p>
                    </div>
                    <a className="ctas" href="https://api.whatsapp.com/send/?phone=5511961206560&text=Ol%C3%A1+vim+pelo+Google%21&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer">Faça sua encomenda</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="untree_co--site-hero overlay ddd">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="col-md-7">
                  <div className="site-hero-contents text-center" data-aos="fade-up">
                    <h1 className="hero-heading cl">Bem Casados Tradicionais</h1>
                    <a className="ctas" href="https://api.whatsapp.com/send/?phone=5511961206560&text=Ol%C3%A1+vim+pelo+Google%21&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer">Peça já!</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="untree_co--site-hero overlay dkk">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="col-md-10">
                  <div className="site-hero-contents text-center" data-aos="fade-up">
                    <h1 className="hero-heading cl">Doces para seus convidados!</h1>
                    <a className="ctas saa" href="https://api.whatsapp.com/send/?phone=5511961206560&text=Ol%C3%A1+vim+pelo+Google%21&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer">Garanta suas lembrancinhas!</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className="untree_co--site-section float-left pb-0 featured-rooms">

           {/* <div className="container pt-0 pb-5">
            <div className="row justify-content-center text-center">  
              <div className="col-lg-6 section-heading" data-aos="fade-up">
                <h3 className="text-center">Featured Rooms</h3>
              </div>
            </div>
          </div>  */}
          <div className="row justify-content-center text-center site-section pt-0 to">
            <div className="col-md-6">
              <h2 className="display-4 h2ss"data-aos="fade-up">Bem-vindo à Lilica Patisserie</h2>
              <p data-aos="fade-up" data-aos-delay="100">Lembrancinhas e doces maravilhosos para compor o seu dia! <br /> Pensando sempre na qualidade e equilíbrio dos sabores, para entregar a perfeição para o momento perfeito!</p>
            </div>
          </div>

          <div className="container-fluid pt-5">
            <div className="suite-wrap overlap-image-1">

              <div className="suite">
                <div className="image-stack">
                  <div className="image-stack-item image-stack-item-top" data-jarallax-element="-50">
                    <div className="overlay"></div>
                    <img src="images/WhatsApp Image 2024-09-16 at 20.05.18.jpeg" alt="Image" className="img-fluid pic1"/>
                  </div>
                  <div className="image-stack-item image-stack-item-bottom">
                    <div className="overlay"></div>
                    <img src="images/WhatsApp Image 2024-09-16 at 16.41.32.jpeg" alt="Image" className="img-fluid pic2"/>
                  </div>
                </div>
              </div> 

              <div className="suite-contents" data-jarallax-element="50">
                <h2 className="suite-title cor">Bem Casados</h2>
                <div className="suite-excerpt">
                  <p className="cor">Um obra prima produzida em nossa cozinha, a união de duas partes que se complementam.</p>
                  <p className="cl cor">Nossos bem casados são totalmente equilibrados em cheiro, sabor e textura, tudo para que cada detalhe seja aprecidado no seu paladar!</p>
                  <a className="ctas dda" href="https://www.lilicapatisserie.com.br/catalogo" rel="noopener noreferrer">Saiba mais!</a>
                </div>
              </div>
            </div>

            <div className="suite-wrap overlap-image-2">

              <div className="suite">
                <div className="image-stack">
                  <div className="image-stack-item image-stack-item-top">
                    <div className="overlay"></div>
                    <img src="images/WhatsApp Image 2024-09-17 at 17.11.29.jpeg" alt="Image" className="img-fluid pic1"/>
                  </div>
                  <div className="image-stack-item image-stack-item-bottom" data-jarallax-element="-50">
                    <div className="overlay"></div>
                    <img src="images/WhatsApp Image 2024-09-16 at 16.47.07.jpeg" alt="Image" className="img-fluid pic2"/>
                  </div>
                </div>
              </div>

              <div className="suite-contents" data-jarallax-element="50">
                <h2 className="suite-title cor">Doces Artesanais</h2>
                <div className="suite-excerpt pr-5">
                  <p className="cor">Sabores exóticos e tradicionais, uma junção de beleza e doçura, perfeito para trasnformar uma mesa linda ou até mesmo um presente!</p>
                  <p className="cor">Surpreendente os sabores, fazendo sempre querermos mais, o doce perfeito para você está aqui!</p>
                  <a className="ctas dda" href="https://www.lilicapatisserie.com.br/catalogo" rel="noopener noreferrer">Escolha o seu!</a>
                </div>
              </div>

            </div>

          </div>
        </div>

        <div className="untree_co--site-section">
          <div className="container">
            <div className="container pt-0 pb-5">
            <div className="row justify-content-center text-center">  
              <div className="col-lg-6 section-heading" data-aos="fade-up">
                <h3 className="text-center cor">Sobre nossos serviços</h3>
              </div>
            </div>
          </div>
            <div className="row custom-row-02192 align-items-stretch">
              <div className="col-md-6 col-lg-4 mb-5" data-aos="fade-up" data-aos-delay="100">
                <div className="media-29191 text-center h-100">
                  <div className="media-29191-icon">
                    <img src="images/pedaco-de-bolo.png" alt="Image" className="img-fluid"/>
                  </div>
                  <h3 className="cor">Degustação</h3>
                  <p className="cor">Gostaria de experimentar nossos produtos? Peça já uma amostra!</p>
                </div>
              </div>
              
              <div className="col-md-6 col-lg-4 mb-5" data-aos="fade-up" data-aos-delay="200">
                <div className="media-29191 text-center h-100">
                  <div className="media-29191-icon">
                    <img src="images/shipped_411763.png" alt="Image" className="img-fluid"/>
                  </div>
                  <h3 className="cor">Frete</h3>
                  <p className="cor">Ficou longe? Calculamos um frete para você!</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 mb-5" data-aos="fade-up" data-aos-delay="100">
                <div className="media-29191 text-center h-100">
                  <div className="media-29191-icon">
                    <img src="images/quality_1892654.png" alt="Image" className="img-fluid"/>
                  </div>
                  <h3 className="cor">Qualidade garantida</h3>
                  <p className="cor">Produzidos com ingredientes de qualidade!</p>
                </div>
              </div>
              
              <div className="col-md-6 col-lg-4 mb-5" data-aos="fade-up" data-aos-delay="200">
                <div className="media-29191 text-center h-100">
                  <div className="media-29191-icon">
                    <img src="images/best-price_5619121.png" alt="Image" className="img-fluid"/>
                  </div>
                  <h3 className="cor">Preço acessível</h3>
                  <p className="cor">Doces incríveis e lindos para compor o seu evento, com o melhor custo benefício!</p>
                </div>
              </div>
              
              <div className="col-md-6 col-lg-4 mb-5" data-aos="fade-up" data-aos-delay="100">
                <div className="media-29191 text-center h-100">
                  <div className="media-29191-icon">
                    <img src="images/hands-holding-diamond_10416158.png" alt="Image" className="img-fluid"/>
                  </div>

                  <h3 className="cor">Produtos Artesanais</h3>
                  <p className="cor">Feitos artesanalmente desde a produção até a embalagem!</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 mb-5" data-aos="fade-up" data-aos-delay="200">
                <div className="media-29191 text-center h-100">
                  <div className="media-29191-icon">
                    <img src="images/accountable_17427205.png" alt="dl" className="img-fluid"/>
                  </div>
                  <h3 className="cor">Personalizamos do seu jeito!</h3>
                  <p className="cor">Qual a sua ideia? Personalizamos sua encomenda para que combine com o seu evento!</p>
                </div>
              </div>

            </div>
          </div>
        </div>
        

        
        

        <div className="untree_co--site-section">
          <div className="container">
            <div className="row">
              <div className="col-md-4 section-heading" data-aos="fade-up">
                <h3 className="text-left cor">Contate-nos</h3>
                <div className="w-75">
                  <p className="cl cor">Tem alguma dúvida ou gostaria de fazer uma degustação ou orçamento?
                    Fale conosco! Estamos aqui para ajudar e tornar o seu evento ainda
                    mais especial com nossos deliciosos produtos.</p>
                  </div>
                  <a className="ctas dda" href="https://api.whatsapp.com/send/?phone=5511961206560&text=Ol%C3%A1+vim+pelo+Google%21&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer">Entre em contato!</a>
              </div>
              
              <div className="col-md-4">
                <div className="post-entry" data-aos="fade-up" data-aos-delay="100">
                  <iframe width="600"
  height="400" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3659.150201790478!2d-46.7303584!3d-23.4910988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cef93dd088ed3d%3A0x3f70f277e7dea67e!2sR.%20Marcos%20Frankenthal%2C%20151%20-%20Ch%C3%A1cara%20Inglesa%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2005141-100!5e0!3m2!1spt-BR!2sbr!4v1722088165976!5m2!1spt-BR!2sbr"></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>

    
        <div className="untree_co--site-section py-5 bg-body-darker cta">
          <div className="container">
            <div className="row">
              <div className="section__container">
                <div className="header">
                  <h1 className="cor">Avaliações</h1>
                  <p className="cor">Pessoas que nos escolheram!</p>
                </div>
                <div className="testimonials__grid">
                  <div className="card">
                    <span><i className="ri-double-quotes-l"></i></span>
                    <p className="cor">
                      Que experiência incrível! Gente, não tem como se arrepender quando se investe em qualidade. Rodei São Paulo para conhecê-los e valeu cada segundo. Que capricho, que atendimento e que doce PERFEITO. Só confiem. Ganharam uma cliente e fã.
                    </p>
                    <hr />
                    <img src="images/user.png" alt="user" />
                    <p className="name cor">Amanda Alves</p>
                  </div>
                  <div className="card">
                    <span><i className="ri-double-quotes-l"></i></span>
                    <p className="cor">
                      Foram super queridos desde a degustação até a entrega! O Bem-casado é bem molhadinho e na medida certa do doce! Comprei para o meu casamento e não me arrependo, super recomendo ❤️❤️
                    </p>
                    <hr />
                    <img src="images/user.png" alt="user" />
                    <p className="cor name">Maria Karoline De Sousa Alencar</p>
                  </div>
                  <div className="card">
                    <span><i className="ri-double-quotes-l"></i></span>
                    <p className="cor">
                      Comprei por ser bem próximo da minha casa, não conhecia. Quando abri a caixa, fiquei encantada com o capricho. Bem casado DELICIOSO !!!! AMEI.
Pratos recomendados
Bem Casado
                    </p>
                    <hr />
                    <img src="images/user.png" alt="user" />
                    <p className="cor name">Ferreirangela13 Angela Ferreira</p>
                  </div>
                </div>
                <div className="footer">
                  <a className="ctas dda" href="https://www.google.com/search?si=ACC90nwO9xEzySNrBbeVuYhtSl5s84rzWVZlRreGH3L-IdoVybOPGo_7z_CvDm1yog9fpK-NmGMMIf2hTSNUru-lBxUGbzQ5Z0y3iAIzRn6VeELASmDNWJfTeXKU520OUGZZpRdSmnwSBn2ChIyaZ0JUGq6wxLxRDjgs5lZ5FYi7KHSIFQZuw_Q%3D&q=lilica%20patisserie%20avalia%C3%A7%C3%B5es&sa=X&ved=0CBcQqe0LahcKEwiQwOH06tGIAxUAAAAAHQAAAAAQBg&biw=1920&bih=966&dpr=1#mpd=~3978171892568153591/customers/reviews" target="_blank" rel="noopener noreferrer">Leia mais!</a>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
     
     <Footer />

    </div>

     {/* Search */}
    <div className="search-wrap">
      <a href="#" className="close-search js-search-toggle">
        <span>Close</span>
      </a>
      <div className="container">
        <div className="row justify-content-center align-items-center text-center">
          <div className="col-md-12">
            <form action="#">
              <input type="search" name="s" id="s" placeholder="Type a keyword and hit enter..."  autoComplete="false"/>
            </form>    
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default App
