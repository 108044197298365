import React from 'react'

function ButtonWpp() {
  return (
    <div>
        <a href="https://wa.me/5511961206560?text=Olá%20vim%20pelo%20Google!" className="whatsapp-button" target="_blank">
            <img className='imswe' src="images/whatsapp.png" alt="whatsapp" />
</a>
    </div>
  )
}

export default ButtonWpp