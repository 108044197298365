import React from 'react';

const Dobra4 = () => {
    const testimonials = [
        {
            text: "“Que experiência incrível! Gente, não tem como se arrepender quando se investe em qualidade. Rodei São Paulo para conhecê-los e valeu cada segundo. Que capricho, que atendimento e que doce PERFEITO. Só confiem. Ganharam uma cliente e fã.”",
            author: "— Amanda Alves"
        },
        {
            text: "“Foram super queridos desde a degustação até a entrega! O Bem-casado é bem molhadinho e na medida certa do doce! Comprei para o meu casamento e não me arrependo, super recomendo ❤️❤️”",
            author: "— Maria Karoline De Sousa Alencar"
        },
        {
            text: "“Comprei por ser bem próximo da minha casa, não conhecia. Quando abri a caixa, fiquei encantada com o capricho. Bem casado DELICIOSO !!!! AMEI. Pratos recomendados Bem Casado”",
            author: "— Ferreirangela13 Angela Ferreira"
        }
    ];

    const logos = [
        { src: 'logo1.png', alt: 'Logo Evento 1' },
        { src: 'logo2.png', alt: 'Logo Evento 2' },
        { src: 'logo3.png', alt: 'Logo Evento 3' }
        // Adicione mais logos se necessário
    ];

    return (
        <section id='depoimentos' className="dobra4-testimonials">
            <h2>Depoimentos</h2>
            <div className="testimonials">
                {testimonials.map((testimonial, index) => (
                    <div key={index} className="testimonial-item">
                        <p>{testimonial.text}</p>
                        <span>{testimonial.author}</span>
                    </div>
                ))}
                <div className="cta-container">
                <a href='https://api.whatsapp.com/send/?phone=5511961206560&text=Ol%C3%A1+vim+pelo+Google%21&type=phone_number&app_absent=0' className="cta-button">Solicite sua cotação agora e leve a doçura para sua celebração!</a>
            </div>
            </div>
            {/* <div className="social-proof">
                <h3>Principais Celebrations</h3>
                <div className="logos">
                    {logos.map((logo, index) => (
                        <img key={index} src={logo.src} alt={logo.alt} className="logo" />
                    ))}
                </div>
            </div> */}
        </section>
    );
};

export default Dobra4;
