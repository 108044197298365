import React from 'react'
import Footer from './Footer'
import ButtonWpp from './ButtonWpp'

function Contato() {
  return (
    <div>

      <ButtonWpp />

      <div id="untree_co--overlayer"></div>
    <div className="loader">
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>

    
    <nav className="untree_co--site-mobile-menu">
      <div className="close-wrap d-flex">
        <a href="#" className="d-flex ml-auto js-menu-toggle">
          <span className="close-label">Close</span>
          <div className="close-times">
            <span className="bar1"></span>
            <span className="bar2"></span>
          </div>
        </a>
      </div>
      <div className="site-mobile-inner"></div>
    </nav>
    <div className="untree_co--site-wrap">
        <nav className="untree_co--site-nav js-sticky-nav">
        <div className="container d-flex align-items-center">
          <div className="logo-wrap">
            <a href="/"><img className="logo-fitss" src="images/Logo_Principal-removebg-preview.png" alt="Lilica"/></a>
          </div>
          <div className="site-nav-ul-wrap text-center d-none d-lg-block">
            <ul className="site-nav-ul js-clone-nav">
              <li><a href='/'>Home</a></li>
              <li>
              <a href='/catalogo'>Catálogo de produtos</a>
              </li>
              <li><a href="/galeria">Galeria</a></li>
              <li><a href="/lilica">Sobre nós</a></li>
              <li className="active"><a href="/contato">Contato</a></li>
            </ul>
          </div>
          <div className="icons-wrap text-md-right">

            <ul className="icons-top d-none d-lg-block">
              
              {/* <li>
                <a href="#"><span className="icon-facebook"></span></a>
              </li> */}
              <li>
              <a href="https://www.instagram.com/lilica.patisseriee" target='_blank'><span className="icon-instagram"></span></a>
              </li>
            </ul>

            {/* Mobile Toggle */}
            <a href="#" className="d-block d-lg-none burger js-menu-toggle" data-toggle="collapse" data-target="#main-navbar">
              <span></span>
            </a>
          </div>
        </div>
      </nav>
        <main className="untree_co--site-main">
        

        <div className="untree_co--site-hero inner-page bg-light lnvj">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-9">
                <div className="site-hero-contents" data-aos="fade-up">
                  <h1 className="hero-heading cor">Contate-nos</h1>
                  <div className="sub-text w-75">
                    <p className='kmdkcnn'>Entre em contato conosco, tiraremos qualquer dúvida!</p>
                    <a className="ctas dda" href="https://api.whatsapp.com/send/?phone=5511961206560&text=Ol%C3%A1+vim+pelo+Google%21&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer">Entrar em contato!</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </main>

      <Footer />
      </div>
    </div>
  )
}

export default Contato