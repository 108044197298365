import React from 'react'
import Footer from './Footer';
import ButtonWpp from './ButtonWpp';

function Galeria() {
  return (
    <div>

      <ButtonWpp />

        <div id="untree_co--overlayer"></div>
    <div className="loader">
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>

    
    <nav className="untree_co--site-mobile-menu">
      <div className="close-wrap d-flex">
        <a href="#" className="d-flex ml-auto js-menu-toggle">
          <span className="close-label">Close</span>
          <div className="close-times">
            <span className="bar1"></span>
            <span className="bar2"></span>
          </div>
        </a>
      </div>
      <div className="site-mobile-inner"></div>
    </nav>
    <div className="untree_co--site-wrap">
        <nav className="untree_co--site-nav js-sticky-nav">
        <div className="container d-flex align-items-center">
          <div className="logo-wrap">
            <a href="/"><img className="logo-fitss" src="images/Logo_Principal-removebg-preview.png" alt="Lilica"/></a>
          </div>
          <div className="site-nav-ul-wrap text-center d-none d-lg-block">
            <ul className="site-nav-ul js-clone-nav">
              <li><a href='/'>Home</a></li>
              <li>
              <a href='/catalogo'>Catálogo de produtos</a>
              </li>
              <li className="active"><a href="/galeria">Galeria</a></li>
              <li><a href="/lilica">Sobre nós</a></li>
              <li><a href="/contato">Contato</a></li>
            </ul>
          </div>
          <div className="icons-wrap text-md-right">

            <ul className="icons-top d-none d-lg-block">
              
              {/* <li>
                <a href="#"><span className="icon-facebook"></span></a>
              </li> */}
              <li>
              <a href="https://www.instagram.com/lilica.patisseriee" target='_blank'><span className="icon-instagram"></span></a>
              </li>
            </ul>

            {/* Mobile Toggle */}
            <a href="#" className="d-block d-lg-none burger js-menu-toggle" data-toggle="collapse" data-target="#main-navbar">
              <span></span>
            </a>
          </div>
        </div>
      </nav>
        <main className="untree_co--site-main">
        

        <div className="untree_co--site-hero inner-page skkkao">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-7 text-center">
                <div className="site-hero-contents" data-aos="fade-up">
                  <h1 className="hero-heading text-white">Galeria</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="untree_co--site-section">

          <div className="container-fluid px-0">

            <div className="row justify-content-center text-center pt-0 pb-5">  
              <div className="col-lg-6 section-heading" data-aos="fade-up">
                <h3 className="text-center lkdcc">Nosso Trabalho</h3>
              </div>
            </div>

            <div className="row align-items-stretch">
              <div className="col-9 relative" data-aos="fade-up" data-aos-delay="">
                <div className="owl-carousel owl-gallery-big">
                  <div className="slide-thumb bg-image ccop"></div>
                  <div className="slide-thumb bg-image ecef"></div>
                  <div className="slide-thumb bg-image maldo"></div>
                  <div className="slide-thumb bg-image jldkc"></div>
                </div>

                <div className="slider-counter text-center"></div>

              </div>
              <div className="col-3 relative"  data-aos="fade-up" data-aos-delay="100">

                <div className="owl-carousel owl-gallery-small">
                  <div className="slide-thumb bg-image opjfj"><a href="#"></a></div>
                  <div className="slide-thumb bg-image s32s"><a href="#"></a></div>
                  <div className="slide-thumb bg-image dvrsf"><a href="#"></a></div>
                  <div className="slide-thumb bg-image dkcnjdc"><a href="#"></a></div>
                </div>

              </div>
            </div>
          </div>

        </div>

        <div className="untree_co--site-section">
          <div className="container">
            <div className="row justify-content-center text-center pt-0 pb-5">  
              <div className="col-lg-6 section-heading" data-aos="fade-up">
                <h3 className="text-center djd">Mais fotos</h3>
              </div>
            </div>
            <div className="row gutter-2">
              <div className="col-md-6" data-aos="fade-up" data-aos-delay="">
                <a href="images/img_1.jpg" data-fancybox="gallery">
                  <img src="images/WhatsApp Image 2024-09-16 at 16.41.09 (1).jpeg" alt="Image" className="img-fluid"/>
                </a>
              </div>
              <div className="col-md-6" data-aos="fade-up" data-aos-delay="100">
                <a href="images/img_2.jpg" data-fancybox="gallery">
                  <img src="images/WhatsApp Image 2024-09-16 at 16.41.32.jpeg" alt="Image" className="img-fluid"/>
                </a>
              </div>
              <div className="col-md-4" data-aos="fade-up" data-aos-delay="100">
                <a href="images/img_1.jpg" data-fancybox="gallery">
                  <img src="images/WhatsApp Image 2024-09-16 at 16.45.07.jpeg" alt="Image" className="img-fluid"/>
                </a>
              </div>
              <div className="col-md-4" data-aos="fade-up" data-aos-delay="200">
                <a href="images/img_1.jpg" data-fancybox="gallery">
                  <img src="images/WhatsApp Image 2024-09-16 at 16.44.58.jpeg" alt="Image" className="img-fluid"/>
                </a>
              </div>
              <div className="col-md-4" data-aos="fade-up" data-aos-delay="300">
                <a href="images/img_1.jpg" data-fancybox="gallery">
                  <img src="images/WhatsApp Image 2024-09-16 at 16.45.14.jpeg" alt="Image" className="img-fluid"/>
                </a>
              </div>
              <div className="col-md-6" data-aos="fade-up" data-aos-delay="">
                <a href="images/img_1.jpg" data-fancybox="gallery">
                  <img src="images/WhatsApp Image 2024-09-16 at 16.42.19.jpeg" alt="Image" className="img-fluid"/>
                </a>
              </div>
              <div className="col-md-6" data-aos="fade-up" data-aos-delay="100">
                <a href="images/img_2.jpg" data-fancybox="gallery">
                  <img src="images/WhatsApp Image 2024-09-16 at 16.42.43.jpeg" alt="Image" className="img-fluid"/>
                </a>
              </div>
              <div className="col-md-4" data-aos="fade-up" data-aos-delay="100">
                <a href="images/img_1.jpg" data-fancybox="gallery">
                  <img src="images/WhatsApp Image 2024-09-16 at 16.47.23.jpeg" alt="Image" className="img-fluid"/>
                </a>
              </div>
              <div className="col-md-4" data-aos="fade-up" data-aos-delay="200">
                <a href="images/img_1.jpg" data-fancybox="gallery">
                  <img src="images/WhatsApp Image 2024-09-16 at 16.47.07 (1).jpeg" alt="Image" className="img-fluid"/>
                </a>
              </div>
              <div className="col-md-4" data-aos="fade-up" data-aos-delay="300">
                <a href="images/img_1.jpg" data-fancybox="gallery">
                  <img src="images/WhatsApp Image 2024-09-16 at 16.47.30.jpeg" alt="Image" className="img-fluid"/>
                </a>
              </div>
            </div>
          </div>
        </div>


      </main>
      <Footer />
      </div>
    </div>
  )
}

export default Galeria