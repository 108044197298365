import React from 'react';

const PaginaObrigado = () => {
    return (
        <div className="pagina-obrigado">
            {/* Seção 1: Mensagem de Agradecimento */}
            <section className="agradecimento">
                <img src="images/Logo_Principal-removebg-preview.png" alt="Lilica" className='log_tp' />
                <h1>Obrigado por Confiar na Lilica Patisserie!</h1>
                <p className="texto-confirmacao">
                    Recebemos suas informações e estamos muito felizes por fazer parte de um momento tão especial! 
                    Em breve, entraremos em contato por meio do telefone ou e-mail informado para responder à sua 
                    cotação e tirar todas as suas dúvidas.
                </p>
                <p className="subtexto">
                    Nosso objetivo é ajudar a transformar sua celebração em uma experiência doce e inesquecível. 
                    Fique atento(a), pois entraremos em contato o mais breve possível!
                </p>
            </section>

            {/* Seção 2: Chamada para Seguir no Instagram */}
            <section className="seguir-instagram">
                <p>Enquanto isso, que tal nos acompanhar no Instagram? Lá você encontrará inspirações para sua celebração, 
                    além de fotos e novidades de nossos Bem Casados, Bem Nascidos, Bem Vividos e Bem Batizados.
                </p>
                <a 
                    href="https://www.instagram.com/lilica.patisserie/" 
                    rel="noopener noreferrer" 
                    className="botao-instagram"
                >
                    Siga-nos no Instagram
                </a>
            </section>

            {/* Seção 3: Sugestão de Inspirações */}
            <section className="sugestao-inspiracao">
                <p className="texto-inspirador">
                    Aproveite para sonhar com cada detalhe e visualize como nossos doces podem adicionar ainda mais encanto 
                    ao seu evento. Acompanhe nossos stories e posts para ver todas as novidades!
                </p>
            </section>

            {/* Seção 4: Informação sobre o Desconto */}
            <section className="informacao-desconto">
                <p className="texto-desconto">
                    💖 Aproveite 10% de desconto ao realizar sua cotação antes da data indicada! 
                    Ao receber nossa mensagem, informe o cupom <strong>MEU_BEM_CASADO329o</strong> para garantir seu desconto especial. 💖
                </p>
            </section>
        </div>
    );
};

export default PaginaObrigado;
