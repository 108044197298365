import React from 'react'
import Footer from './Footer'
import ButtonWpp from './ButtonWpp'

function Lilica() {
  return (
    <div>

      <ButtonWpp />

        <div id="untree_co--overlayer"></div>
    <div className="loader">
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>

    
    <nav className="untree_co--site-mobile-menu">
      <div className="close-wrap d-flex">
        <a href="#" className="d-flex ml-auto js-menu-toggle">
          <span className="close-label">Close</span>
          <div className="close-times">
            <span className="bar1"></span>
            <span className="bar2"></span>
          </div>
        </a>
      </div>
      <div className="site-mobile-inner"></div>
    </nav>
    <div className="untree_co--site-wrap">
        <nav className="untree_co--site-nav js-sticky-nav">
        <div className="container d-flex align-items-center">
          <div className="logo-wrap">
            <a href="/"><img className="logo-fitss" src="images/Logo_Principal-removebg-preview.png" alt="Lilica"/></a>
          </div>
          <div className="site-nav-ul-wrap text-center d-none d-lg-block">
            <ul className="site-nav-ul js-clone-nav">
              <li><a href='/'>Home</a></li>
              <li>
              <a href='/catalogo'>Catálogo de produtos</a>
              </li>
              <li><a href="/galeria">Galeria</a></li>
              <li className="active"><a href="/lilica">Sobre nós</a></li>
              <li><a href="/contato">Contato</a></li>
            </ul>
          </div>
          <div className="icons-wrap text-md-right">

            <ul className="icons-top d-none d-lg-block">
              
              {/* <li>
                <a href="#"><span className="icon-facebook"></span></a>
              </li> */}
              <li>
              <a href="https://www.instagram.com/lilica.patisseriee" target='_blank'><span className="icon-instagram"></span></a>
              </li>
            </ul>

            {/* Mobile Toggle */}
            <a href="#" className="d-block d-lg-none burger js-menu-toggle" data-toggle="collapse" data-target="#main-navbar">
              <span></span>
            </a>
          </div>
        </div>
      </nav>

        <main className="untree_co--site-main">
        

        <div className="untree_co--site-hero inner-page bg-light bnff">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-9">
                <div className="site-hero-contents" data-aos="fade-up">
                  <h1 className="hero-heading cor">Sobre Lilica Patisserie</h1>
                  <div className="sub-text w-75">
                    <p className="cor">Para momentos especiais uma Patisserie especial!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="untree_co--site-section">

          <div className="container-fluid px-md-0">

            <div className="row justify-content-center text-center pt-0 pb-5">  
              <div className="col-lg-6 section-heading" data-aos="fade-up">
                <h3 className="text-center cor">Conhecendo Lilica</h3>
              </div>
            </div>
            
            <div className="row no-gutters">
              <div className="col-md-4"  data-aos="fade-up">
                 <img src="images/2-removebg-preview.png" alt="Image" className="img-fluid" data-jarallax-element="-140"/>
              </div>
              <div className="col-md-8">
                <div className="row justify-content-center">
                  <div className="col-md-10">
                    <h3 className="mb-4 cor" data-aos="fade-up">Família</h3>
                    <div className="row">
                      <div className="col-md-6" data-aos="fade-up">
                        
                        <p>A Lilica Patisserie não é apenas uma confeitaria como qualquer outra! Ela entende como é importante as pessoas que nos rodeiam e os momentos que passamos com elas!</p>                  

                        <p>De uma receita passada de uma geração para outra Lilica surgiu! Através do Bem casados entregamos muitos sabores para muitas pessoas, e um legado se formou!</p>
                  
                      </div>
                      <div className="col-md-6"  data-aos="fade-up">
                        <p>Sempre nos aperfeiçoando e alcançando mais pessoas, a Patisserie continua a crescer e logo será passada para a próxima geração, nossos sabores são geracionais!</p>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
            
          </div>

        </div>

        <div className="untree_co--site-section pt-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9">
                <div className="row mb-5 align-items-center">
                  <div className="col-md-5 mr-auto">
                    <h2 className="display-4">Por que Lilica?</h2>
                  </div>
                  <div className="col-md-7 ml-auto">
                     <p>Nossos serviços são totalmente personalizados, pensando na melhor decoração e sabor para seu evento! </p>
                     <p>Proporcionando um experiência incrível para seus convidados ou até mesmo uma pessoa muito querida!</p>                  
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <img src="images/WhatsApp Image 2024-09-16 at 20.32.11.jpeg" alt="Image" className="img-fluid"/>
              </div>
            </div>
          </div>
        </div>

        <div className="untree_co--site-section py-5 bg-body-darker cta">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h3 className="p-0 cdd">Gostaria de experimentar nossos bem casados? Garanta já sua amostra gratuita! <a>Às sextas e sábados!</a></h3>
                <a className="ctas dda" href="https://api.whatsapp.com/send/?phone=5511961206560&text=Ol%C3%A1+vim+pelo+Google%21&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer">Reserve sua amostra!</a>
              </div>
            </div>
          </div>
        </div>


      </main>

        <Footer />
        </div>
    </div>
  )
}

export default Lilica