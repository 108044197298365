import React from 'react';

const Dobra2 = () => {
    return (
        <section className="story-section">
            <div className="story-content">
                <h2>A História e a Experiência Única do Bem Casado</h2>
                <p className="inspirational-text">
                    “Na Lilica Patisserie, acreditamos que cada celebração merece ser acompanhada de um doce que simbolize carinho, amor e união. Nossos Bem Casados são criados artesanalmente, com uma massa delicada e um recheio cremoso que derrete na boca, garantindo uma experiência única e inesquecível para cada convidado.”
                </p>
                <div className="product-features">
                    <h3>Benefícios e Características do Produto</h3>
                    <ul>
                        <li><strong>Aroma Delicado:</strong> Sutil toque de baunilha, sem cheiro de ovo.</li>
                        <li><strong>Textura Irresistível:</strong> Massa macia, que desmancha na boca.</li>
                        <li><strong>Sabor Equilibrado:</strong> Doce de leite cremoso que não sobrecarrega o paladar.</li>
                        <li><strong>Cobertura Encantadora:</strong> Uma fina camada de açúcar que agrega charme e delicadeza.</li>
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default Dobra2;
