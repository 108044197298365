import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Dobra3 = () => {
    const images = [
        { src: 'images/bemcasado1.jpeg', alt: 'Bem Casados elegantes' },
        { src: 'images/bemcasado2.jpeg', alt: 'Bem Nascidos delicados' },
        { src: 'images/bemcasado3.jpeg', alt: 'Bem Vividos celebrativos' },
        { src: 'images/bemcasado4.jpeg', alt: 'Bem Batizados encantadores' },
        { src: 'images/bemcasado5.jpeg', alt: 'Bem Batizados encantadores' },
        { src: 'images/bemcasado6.jpeg', alt: 'Bem Batizados encantadores' },
        { src: 'images/bemcasado7.jpeg', alt: 'Bem Batizados encantadores' },
        { src: 'images/bemcasado8.jpeg', alt: 'Bem Batizados encantadores' },
        { src: 'images/bemcasado9.jpeg', alt: 'Bem Batizados encantadores' },
        { src: 'images/bemcasado10.jpeg', alt: 'Bem Batizados encantadores' },
        { src: 'images/bemcasado11.jpeg', alt: 'Bem Batizados encantadores' },
        { src: 'images/bemcasado12.jpeg', alt: 'Bem Batizados encantadores' },
        // Adicione mais imagens se desejar
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
        <section id='galeria' className="dobra3-gallery">
            <h2>Galeria de Inspiração</h2>
            <Slider {...settings} className="gallery-slider">
                {images.map((image, index) => (
                    <div key={index} className="gallery-item">
                        <img src={image.src} alt={image.alt} />
                        <p>{image.label}</p>
                    </div>
                ))}
            </Slider>
            <div className="cta-container">
                <a href='https://api.whatsapp.com/send/?phone=5511961206560&text=Ol%C3%A1+vim+pelo+Google%21&type=phone_number&app_absent=0' className="cta-button">Solicite Sua Cotação e Descubra o Sabor!</a>
            </div>
        </section>
    );
};

export default Dobra3;
