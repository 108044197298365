import React from 'react'

function Footer() {
  return (
    <div>
         <footer className="untree_co--site-footer">

<div className="container">
  <div className="row">
    <div className="col-md-4 pr-md-5">
      <p className="cor">"Lilica Patisserie cria doces perfeitos e deliciosos, proporcionando uma experiência incrível para o que você precisa!"</p>
      <p><a href="/lilica" className="readmore">Leia mais</a></p>
    </div>
    <div className="col-md-8 ml-auto">
      <div className="row">
        <div className="col-md-3">
          <h3 className="cor">Navegação</h3>
          <ul className="list-unstyled">
            <li><a href="/" className="cor">Home</a></li>
            <li><a href="/catalogo" className="cor">Catálogo de produtos</a></li>
            <li><a href="/galeria" className="cor">Galeria</a></li>
            <li><a href="/lilica" className="cor">Sobre nós</a></li>
            <li><a href="/contato" className="cor">Contato</a></li>
          </ul>
        </div>
        <div className="col-md-9 ml-auto">
          <div className="row mb-3">
            <div className="col-md-6">
              <h3 className="cor">Endereço</h3>
              <address className="cor">R. Marcos Frankenthal, 151 - Chácara Inglesa <br/> São Paulo - SP, 05141-100</address>
            </div>
            <div className="col-md-6">
              <h3 className="cor">Telefone</h3>
              <p>
                <a href="https://api.whatsapp.com/send/?phone=5511961206560&text=Ol%C3%A1+vim+pelo+Google%21&type=phone_number&app_absent=0" target="_blank">+55 (11) 96120-6560</a> <br/>
              </p>
            </div>
          </div>

        </div>
        
      </div>
    </div>
  </div>
  <div className="row mt-5 pt-5 align-items-center">
    <div className="col-md-6 text-md-left">
      
      <p>
        Copyright &copy;<script>document.write(new Date().getFullYear());</script> <a href="index.html">Untree.co</a>. All Rights Reserved. Design by <a href="https://untree.co/" target="_blank" className="text-primary">Untree.co</a>
      </p>

    </div>
    <div className="col-md-6 text-md-right">
      <ul className="icons-top icons-dark">
      {/* <li>
        <a href="#"><span className="icon-facebook"></span></a>
      </li> */}
      <li>
      <a href="https://www.instagram.com/lilica.patisseriee" target='_blank'><span className="icon-instagram"></span></a>
      </li>
    </ul>

    </div>
  </div>
</div>

</footer>
    </div>
  )
}

export default Footer